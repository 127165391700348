.carousel {
  max-width: 100%;
  height: 300px; /* Set a fixed height */
}

.carousel .slide {
  width: 100%;
  position: relative;
}

/* Add media queries as needed for different screen sizes */
@media (max-width: 768px) {
  .carousel {
    height: 200px; /* Adjust height for smaller screens */
  }
}

.tagline {
  position: absolute;
  top: 70%;
  left: 50%;
  font-family: "Open sans", sans-serif;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(255, 255, 255, 0.8); /* Adjust the color as needed */
  font-size: 3vw;
}
